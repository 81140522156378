<div class="footer">
	<a
		class="github-link"
		rel="noreferrer"
		target="_blank"
		href="https://github.com/tom-draper/api-analytics"
	>
		<img class="github-logo" height="30px" src="../img/logos/github.png" alt="" />
	</a>
	<div class="logo">API Analytics</div>
	<img class="footer-logo" src="../img/logos/lightning-green.png" alt="" />
</div>

<style scoped>
	.logo {
		font-size: 0.9em;
		color: var(--highlight);
	}
	.footer {
		margin: 1.5em 0 3em;
	}
	.github-logo {
		height: 28px;
		filter: invert(0.24);
		margin-bottom: 30px;
		transition: filter 0.15s;
	}
	.github-logo:hover {
		filter: invert(0.5);
	}
</style>
