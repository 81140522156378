import { SERVER_URL } from './consts';
function getSourceURL() {
    const url = new URL(window.location.href);
    const source = url.searchParams.get('source');
    if (source === '' || source === null) {
        return null;
    }
    return cleanURL(source);
}
function getEnvSourceURL() {
    try {
        return cleanURL(process.env.SERVER_URL);
    }
    catch (e) {
        return null;
    }
}
function cleanURL(url) {
    if (url.endsWith('/')) {
        return url.slice(0, -1);
    }
    return url;
}
export function getServerURL() {
    var _a, _b;
    return (_b = (_a = getSourceURL()) !== null && _a !== void 0 ? _a : getEnvSourceURL()) !== null && _b !== void 0 ? _b : SERVER_URL;
}
